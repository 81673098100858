import img1 from '../img/1.jpg?nf_resize=fit&w=100&h=100';
import img2 from '../img/2.jpg?nf_resize=fit&w=100&h=100';
import img3 from '../img/3.jpg?nf_resize=fit&w=100&h=100';
import img4 from '../img/4.jpg?nf_resize=fit&w=100&h=100';
import img5 from '../img/5.jpg?nf_resize=fit&w=100&h=100';
import img6 from '../img/6.jpg?nf_resize=fit&w=100&h=100';
import img7 from '../img/7.jpg?nf_resize=fit&w=100&h=100';
import img8 from '../img/8.jpg?nf_resize=fit&w=100&h=100';
import img9 from '../img/9.jpg?nf_resize=fit&w=100&h=100';
import img10 from '../img/10.jpg?nf_resize=fit&w=100&h=100';
import img11 from '../img/11.jpg?nf_resize=fit&w=100&h=100';
import img12 from '../img/12.jpg?nf_resize=fit&w=100&h=100';
import img13 from '../img/13.jpg?nf_resize=fit&w=100&h=100';
import img14 from '../img/14.jpg?nf_resize=fit&w=100&h=100';
import img15 from '../img/15.jpg?nf_resize=fit&w=100&h=100';
import img16 from '../img/16.jpg?nf_resize=fit&w=100&h=100';
import black_kite from '../img/black_kite.jpg?nf_resize=fit';
import blue_barbet from '../img/blue_barbet.jpg?nf_resize=fit';
import common_kingfisher from '../img/common_kingfisher.jpg?nf_resize=fit';
import crested from '../img/crested.jpg?nf_resize=fit';
import croc from '../img/croc.jpg?nf_resize=fit';
import cub from '../img/cub.jpg?nf_resize=fit';
import maize from '../img/maize.jpg?nf_resize=fit';
import owlet from '../img/owlet.jpg?nf_resize=fit';
import parrot from '../img/parrot.jpg?nf_resize=fit';
import pied from '../img/pied.jpg?nf_resize=fit';
import plumbeous from '../img/plumbeous.jpg?nf_resize=fit';
import rhino from '../img/rhino.jpg?nf_resize=fit';
import squirrel from '../img/squirrel.jpg?nf_resize=fit';
import stonechat from '../img/stonechat.jpg?nf_resize=fit';
export const photos = [
  {
    src: img1,
    width: 4,
    height: 3
  },
  {
    src: img2,
    width: 3,
    height: 4
  },
  {
    src: img3,
    width: 5,
    height: 4
  },
  {
    src: img4,
    width: 5,
    height: 4
  },
  {
    src: img13,
    width: 2,
    height: 3
  },
  {
    src: img6,
    width: 4,
    height: 3
  },
  {
    src: img12,
    width: 2,
    height: 3
  },
  {
    src: img5,
    width: 5,
    height: 4
  },
  {
    src: img11,
    width: 2,
    height: 3
  },
  {
    src: img7,
    width: 6,
    height: 4
  },
  {
    src: img9,
    width: 2,
    height: 3
  },
  {
    src: img10,
    width: 8,
    height: 4
  },
  {
    src: img14,
    width: 2,
    height: 3
  },
  {
    src: img15,
    width: 50,
    height: 30
  },
  {
    src: img8,
    width: 20,
    height: 30
  },
  {
    src: img16,
    width: 40,
    height: 30
  },
  {
    src: blue_barbet,
    width: 30,
    height: 40
  },
  {
    src: black_kite,
    width: 2500,
    height: 1000
  },
  {
    src: cub,
    width: 3,
    height: 4
  },
  {
    src: common_kingfisher,
    width: 4,
    height: 3
  },
  {
    src: crested,
    width: 4,
    height: 3
  },
  {
    src: maize,
    width: 3,
    height: 4
  },
  {
    src: croc,
    width: 6,
    height: 3
  },
  {
    src: owlet,
    width: 3,
    height: 4
  },
  {
    src: parrot,
    width: 4,
    height: 3
  },
  {
    src: squirrel,
    width: 4,
    height: 4
  },
  {
    src: pied,
    width: 4,
    height: 3
  },
  {
    src: plumbeous,
    width: 4,
    height: 3
  },
  {
    src: rhino,
    width: 6,
    height: 4
  },
  {
    src: stonechat,
    width: 4,
    height: 3
  }
];
